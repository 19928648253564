import React from 'react';
import './Ticker.css';
import TickerItem from './TickerItem/TickerItem';
import fetchJsonp from 'fetch-jsonp';

/* Ticker component that has two TickerItem components as
children. When one TickerItem is exiting, the other enters
containing the data of the next displayed event. This way,
an infinite slider effect is achieved with only 2 children*/
class Ticker extends React.Component {
  state = {
    liveEvents: null,
    tickerEvents: {
      true: null,
      false: null,
    },
    currentLiveEventIndex: 0,
    currentTickerEvent: true,
    animations: {
      true: 'enter',
      false: null
    },
    intervalId: null,
  };

  async componentDidMount() {
    const liveEvents = await this.getLiveEvents();
    /* Set first TickerItem data from retrieved events and
    initiate interval to progress slider every 3 seconds */
    this.setState({
      liveEvents: liveEvents,
      tickerEvents: {
        true: liveEvents[this.state.currentLiveEventIndex],
      },
      intervalId: setInterval(this.setNextEvent, 3000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getLiveEvents = async () => {
    let liveEvents = this.getCachedLiveEvents();
    if(!liveEvents){
      const response = await fetchJsonp("https://api.unicdn.net/v1/feeds/sportsbook/event/live.jsonp?app_id=ca7871d7&app_key=5371c125b8d99c8f6b5ff9a12de8b85a");
      liveEvents = (await response.json()).liveEvents;
      this.cacheLiveEvents(liveEvents);
    }
    return liveEvents;
  }
  cacheLiveEvents(liveEvents){
    localStorage.setItem('liveEvents', JSON.stringify({
      data: liveEvents,
      timestamp: new Date().getTime(),
    }));
  }
  getCachedLiveEvents(){
    const cachedLiveEvents = JSON.parse(localStorage.getItem('liveEvents'));
    // Return cached data if it exists and it has not expired
    if(cachedLiveEvents && new Date().getTime() - cachedLiveEvents.timestamp < 120000){
      return cachedLiveEvents.data;
    }
    // Otherwise, return null
    return null;
  }

  setNextEvent = () => {
    const nextLiveEventIndex = this.getNextLiveEventIndex();
    const nextLiveEvent = this.state.liveEvents[nextLiveEventIndex];
    /* Set the TickerItem that is currently exited to display the next live 
    event and begin its enter animation, and set the current TickerItem to begin 
    its exit animation */
    this.setState((state) => {
      return {
        tickerEvents: {
          ...state.tickerEvents,
          [!state.currentTickerEvent]: nextLiveEvent
        },
        animations: {
          [state.currentTickerEvent]: 'exit',
          [!state.currentTickerEvent]: 'enter'
        },
        currentTickerEvent: !state.currentTickerEvent,
        currentLiveEventIndex: nextLiveEventIndex
      }
    });
  }
  getNextLiveEventIndex = () => {
    /* Return the current live event index incremented if it is not
    the last live event in the array. Otherwise, return 0 so the ticker
    continues from the first live event */
    return (
      this.state.currentLiveEventIndex === this.state.liveEvents.length - 1
      ? 0
      : this.state.currentLiveEventIndex + 1
    );
  }

  render() {
    return (
      <div className="ticker-container">
        {this.state.tickerEvents[true] &&
          <TickerItem 
            liveEvent={this.state.tickerEvents[true]} 
            animation={this.state.animations[true]}
          />
        }
        {this.state.tickerEvents[false] && 
          <TickerItem 
            liveEvent={this.state.tickerEvents[false]} 
            animation={this.state.animations[false]}
          />
        }
      </div>
    );
  }
}

export default Ticker;