import React from 'react';
import './TickerItem.css';

import { VelocityComponent } from 'velocity-react';

import tennisIcon from 'images/icons/tennis.png';
import footballIcon from 'images/icons/football.png';
import basketballIcon from 'images/icons/basketball.png';
import defaultIcon from 'images/icons/default.png';

const ICONS = {
	TENNIS: tennisIcon,
	FOOTBALL: footballIcon,
	BASKETBALL: basketballIcon
};

export default (props) => {
	return (
		// Animate with Velocity.js for IE9 support
		<VelocityComponent
			animation={{
				left: props.animation === 'enter' ? 0: '-100%'
			}}
			/* If the completed animation was exit, pop the item back to the right 
			so that it will enter from the right on the next enter animation */
			complete={(elements) => {
				if(props.animation === 'exit') elements[0].removeAttribute('style')
			}}
			runOnMount={true}
			duration={500}
		>
			<div className="ticker-item">
				<Score score={props.liveEvent.liveData.score}></Score>
				<Teams event={props.liveEvent.event}></Teams>
				<StartTime start={props.liveEvent.event.start}></StartTime>
				<a
					tabIndex="-1"
					href={`https://www.unibet.com/betting#/event/live/${props.liveEvent.event.id}`} 
					target="_blank"
					rel="noopener noreferrer"
				>
					<button tabIndex="-1">Place a bet</button>
				</a>
			</div>			
		</VelocityComponent>
	);
};

const Score = (props) => {
	if(props.score) return (
		<div className="score">
			{props.score.home} - {props.score.away}
		</div>
	);
	return null;
};

const Teams = (props) => {
	return (
		<div className="teams">
			<Icon sport={props.event.sport}></Icon>
			{props.event.homeName} - {props.event.awayName}
		</div>
	)
};
const Icon = (props) => {
	return (
		<img 
			className="icon" 
			src={ICONS[props.sport] || defaultIcon}
			alt=""
		>
		</img>
	)
};

const StartTime = (props) => {
	const start = new Date(props.start);
	const dateString = (
		(isToday(start) ? 'Today' : getFormattedDate(start)) +
		", " +
		getFormattedTime(start)
	);
	return (
		<div className="start-time">
			{dateString}
		</div>
	)
};
const isToday = (date) => {
  const today = new Date()
	return date.toDateString() === today.toDateString();
};
//Add leading 0 to given digit if it is a single digit
const appendLeadingZeroes = (n) => {
  return n < 10 ? "0" + n : n;
};
//Return YYYY-MM-DD string for given Date object
const getFormattedDate = (date) => {
	return (
		date.getFullYear() +
		"-" +
		appendLeadingZeroes(date.getMonth()+1) +
		"-" +
		appendLeadingZeroes(date.getDate())
	)
};
//Return HH:MM format for given Date object
const getFormattedTime = (date) => {
	return (
		appendLeadingZeroes(date.getHours()) +
		":" +
		appendLeadingZeroes(date.getMinutes())
	);
};