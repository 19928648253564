import React from 'react';
import './App.css';
import Ticker from './components/Ticker/Ticker';

function App() {
  return (
    <div id="container">
      <header>
        <div id="logo">Unibet</div>
      </header>
      <div id="content">
        <article>
          <h1>Live matches</h1>
          <p className="preamble">
            Here is a list of matches that are live right now.
          </p>
          <div id="live-matches">
            <Ticker/>
          </div>
          <aside>
            <h2>Live betting</h2>
            <p>Place your bets as the action unfolds. We offer a wide selection of live betting events and you can place both single and combination bets.</p>
            <p>You will be able to see an in-play scoreboard with the current result and match stats, while on selected events you will also be able to watch the action live with Unibet TV on the desktop site.</p>
          </aside>
        </article>
      </div>
      <footer>
        <div className="inner">
          <p>&copy; 1997-2015, Unibet. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
